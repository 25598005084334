import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import createRoutes from '../src/routes';
import './styles/global.scss';

function App() {

  return (
  <BrowserRouter>
    {createRoutes()}
  </BrowserRouter>
  );
}
console.log('= ENV =', process.env.REACT_APP_ENV);

export default App;
