import { FC, useEffect, useState, useCallback, useRef } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import React from 'react';
import config from '../../config';

const PartBoxGame: FC = () => {
  const [isShowGame, setIsShowGame] = useState<boolean>(false);
  const [isLoadScriptComplete, setIsLoadScriptComplete] =
    useState<boolean>(false);
  const versionBuild = config.game_build_version;
  const env = process.env.REACT_APP_ENV || 'dev';
  const versionGameBuildFolder = `game_build_${env}`;

  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: `/${versionGameBuildFolder}/GamePacman_${versionBuild}.loader.js`,
    dataUrl: `/${versionGameBuildFolder}/GamePacman_${versionBuild}.data.unityweb`,
    frameworkUrl: `/${versionGameBuildFolder}/GamePacman_${versionBuild}.framework.js.unityweb`,
    codeUrl: `/${versionGameBuildFolder}/GamePacman_${versionBuild}.wasm.unityweb`,
  });
  const gameRef = useRef<any>(null);

  const loadingPercentage = Math.round(loadingProgression * 100);

  const addThirdwebUnityBridge = () => {
    const loaderUrl = `/lib/thirdweb-unity-bridge.js`;
    const existingScript = document.querySelector(`script[src="${loaderUrl}"]`);

    if (existingScript) {

      return;
    }
    const script = document.createElement('script');
    script.src = loaderUrl;
    script.async = true;
    script.onload = () => {
      setIsLoadScriptComplete(true);
    };
    document.body.appendChild(script);
  };
  const loadGameLoaderJS = () => {
    const loaderUrl = `${versionGameBuildFolder}/GamePacman_${versionBuild}.loader.js`;
    const existingScript = document.querySelector(`script[src="${loaderUrl}"]`);

    if (existingScript) {

      return;
    }
    const script = document.createElement('script');
    script.src = loaderUrl;
    script.onload = () => {

      addThirdwebUnityBridge();
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    loadGameLoaderJS();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    setIsShowGame(true);
    const timer1 = setTimeout(() => {
      console.clear();
      setIsShowGame(true)
    }, 2 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  }, [isLoaded]);

  return (
    <div className="box-game" ref={gameRef}>
      {(!isLoaded || (isLoaded && !isShowGame)) && (
        <div className="loading-overlay">
          <div>
            <img src="/images/pacman-racing-loading.gif" />
            <div className="loading-overlay__process">
              <div style={{ width: `${loadingPercentage}%` }}></div>
            </div>
          </div>
        </div>
      )}

      {isLoadScriptComplete && (
        <div className="box-game__conent-game">
          <Unity
            unityProvider={unityProvider}
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      )}
    </div>
  );
};

export default PartBoxGame;
